<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <stats-card header-color="indigo">
          <template slot="header">
            <div class="card-icon" @click="menuVisible = !menuVisible">
              <md-icon v-if="!load">coronavirus</md-icon>
              <i class="md-icon md-icon-font md-theme-default" v-if="load">
                <md-progress-spinner
                  :md-diameter="30"
                  :md-stroke="3"
                  md-mode="indeterminate"
                ></md-progress-spinner>
              </i>
            </div>
          </template>
          <template slot="content">
            <md-app>
              <md-app-toolbar class="md-primary" style="padding: 4px;">
                <div class="md-toolbar-section-end">
                  <md-button
                    class="md-icon-button md-primary"
                    @click="toggleMenu"
                    v-if="!menuVisible"
                  >
                    <md-icon>menu</md-icon>
                  </md-button>
                </div>
              </md-app-toolbar>

              <md-app-drawer
                :md-active.sync="menuVisible"
                md-persistent="full"
                md-right
                md-swipeable
              >
                <md-toolbar class="md-primary" style="padding: 6px;">
                  <div class="md-toolbar-section-start">
                    <md-button
                      class="md-icon-button md-dense md-white"
                      @click="toggleMenu"
                    >
                      <md-icon>keyboard_arrow_right</md-icon>
                    </md-button>
                  </div>
                  <md-button class="md-raised md-white" @click="changeControl">
                    <md-icon>insights</md-icon>
                    Atualizar
                  </md-button>
                </md-toolbar>

                <div class="full-control">
                  <div class="list">
                    <md-table v-model="datasets">
                      <md-table-row
                        slot="md-table-row"
                        slot-scope="{ item }"
                        md-selectable="single"
                      >
                        <md-table-cell md-label="Ano" md-sort-by="year">
                          <md-switch
                            v-model="years"
                            :value="item.year"
                            @change="changeControl"
                          ></md-switch>
                        </md-table-cell>
                        <md-table-cell md-label="Opção" md-sort-by="text">{{
                          item.year
                        }}</md-table-cell>
                      </md-table-row>
                    </md-table>

                    <md-table v-model="type_dates">
                      <md-table-row
                        slot="md-table-row"
                        slot-scope="{ item }"
                        md-selectable="single"
                      >
                        <md-table-cell md-label="Tipo" md-sort-by="action">
                          <md-switch
                            v-model="type_date"
                            :value="item.action"
                            @change="changeControl"
                          ></md-switch>
                        </md-table-cell>
                        <md-table-cell md-label="Opção" md-sort-by="text">{{
                          item.text
                        }}</md-table-cell>
                      </md-table-row>
                    </md-table>

                    <md-table v-model="filters">
                      <md-table-row
                        slot="md-table-row"
                        slot-scope="{ item }"
                        md-selectable="single"
                      >
                        <md-table-cell md-label="Filtro" md-sort-by="action">
                          <md-switch
                            v-model="filter"
                            :value="item.action"
                            @change="changeControl"
                          ></md-switch>
                        </md-table-cell>
                        <md-table-cell md-label="Opção" md-sort-by="text">{{
                          item.text
                        }}</md-table-cell>
                      </md-table-row>
                    </md-table>
                  </div>
                </div>
              </md-app-drawer>

              <md-app-content>
                <div id="chart"></div>
              </md-app-content>
            </md-app>
            <md-card>
              <md-card-content
                v-for="(state, index) in statesSearched"
                :key="index"
              >
                <md-table
                  v-model="state.cities"
                  md-sort="name"
                  md-sort-order="asc"
                  md-card
                  md-fixed-header
                >
                  <md-table-toolbar>
                    <div class="md-toolbar-section-start">
                      <h1 class="md-title">Localidades do {{ state.name }}</h1>
                    </div>
                    <div class="md-toolbar-section-end">
                      <md-field md-clearable>
                        <md-input
                          v-model="termSearch"
                          @click.stop="
                            () => {
                              return true;
                            }
                          "
                          @input="
                            searchByName(
                              states[index].cities,
                              termSearch,
                              index
                            )
                          "
                          placeholder="Buscar..."
                        />
                      </md-field>
                      <md-button
                        class="md-raised md-danger"
                        @click="clearSelect"
                        >Limpar</md-button
                      >
                    </div>
                  </md-table-toolbar>

                  <md-table-empty-state
                    md-label="Não localizado"
                    :md-description="
                      `não foi possivel encontar esta localidade '${'text'}'.`
                    "
                  >
                  </md-table-empty-state>

                  <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="ID" md-sort-by="id" md-numeric>{{
                      item.id
                    }}</md-table-cell>
                    <md-table-cell md-label="Cidades" md-sort-by="name">
                      <md-checkbox
                        v-model="citiesSelected"
                        :value="item.id"
                        @change="getCityCases(item.id)"
                      >
                      </md-checkbox>
                      {{ item.name }}
                    </md-table-cell>
                    <md-table-cell
                      md-label="Microrregiões"
                      md-sort-by="microregion_sesapi.name"
                    >
                      <md-checkbox
                        v-model="microregionsSelected"
                        :value="item.microregion_sesapi.id"
                        @change="
                          getMicroregionCases(item.microregion_sesapi.id)
                        "
                      >
                      </md-checkbox>

                      {{ item.microregion_sesapi.name }}
                    </md-table-cell>
                    <md-table-cell
                      md-label="Mesorregiões"
                      md-sort-by="mesoregion_sesapi.name"
                    >
                      <md-checkbox
                        v-model="mesoregionsSelected"
                        :value="item.mesoregion_sesapi.id"
                        @change="getMesoregionCases(item.mesoregion_sesapi.id)"
                      >
                      </md-checkbox>

                      {{ item.mesoregion_sesapi.name }}
                    </md-table-cell>
                  </md-table-row>
                </md-table>
              </md-card-content>
            </md-card>
          </template>

          <template slot="footer">
            <div class="stats"></div>
          </template>
        </stats-card>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { StatsCard } from "@/components";
import ApexCharts from "apexcharts";
import moment from "moment-mini";

export default {
  components: {
    StatsCard
  },
  computed: {},
  async created() {
    this.load = true;
    this.source = this.$route.params.source;
    this.datasetName = this.$route.params.datasetName;
    await this.getDataSets();
    await this.getStates();
  },
  async mounted() {
    let type_date = "Óbitos por";
    let filter = "média móvel por dia";

    this.chartOptions.yaxis.title.text = `${type_date} ${this.datasetName.toUpperCase()} ${filter} Fonte: ${this.source.toUpperCase()}`;
    this.title = this.chartOptions.yaxis.title.text;

    this.welcomeMessage();
  },
  data() {
    return {
      filter: "moving_agv",
      type_date: "deaths",
      actionSelected: String,
      title: "",
      datasetName: "",
      source: "",
      datasetsSelected: [],
      load: false,
      years: [],
      chart: null,
      mesoregionsSelected: [],
      mesoregionsSeries: [],
      termSearch: "",
      states: [],
      citiesSelected: [],
      citiesSeries: [],
      statesSearched: [],
      microregionsSelected: [],
      microregionsSeries: [],
      menuVisible: true,
      expandNews: false,
      expandSingle: false,
      datasets: [],
      series: [],
      chartOptions: {
        series: [],
        chart: { height: 450, type: "line", stacked: false },
        annotations: { points: [] },
        colors: [
          "#d63031",
          "#0984e3",
          "#00b894",
          "#fdcb6e",
          "#e84393",
          "#6c5ce7",
          "#00cec9",
          "#e17055",
          "#fd79a8",
          "#a29bfe",
          "#81ecec",
          "#fab1a0",
          "#ff7675",
          "#74b9ff",
          "#55efc4",
          "#ffeaa7"
        ],
        dataLabels: { enabled: false },
        stroke: { width: [], curve: "smooth" },
        markers: {
          size: 2,
          strokeOpacity: 0.8,
          strokeWidth: 0.5,
          fillOpacity: 0.8
        },
        fill: {
          opacity: [],
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
          }
        },
        yaxis: {
          labels: {},
          title: {
            text: ""
          }
        },
        xaxis: {
          type: "datetime",
          labels: {
            hideOverlappingLabels: true,
            showDuplicates: true,
            format: "dd-MM",
            datetimeFormatter: {
              year: "yyyy",
              month: "dd-MM",
              day: "dd-MM",
              hour: "HH:mm"
            }
          }
        },
        title: {
          text: "",
          align: "right",
          margin: 0,
          offsetX: 0,
          offsetY: 25,
          floating: false,
          style: {
            fontSize: "10px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#858796"
          }
        },
        tooltip: { x: { format: "dd/MM" } }
      },
      filters: [
        {
          action: "per_date",
          text: "Casos por data"
        },
        {
          action: "incidence",
          text: "Inciência por data"
        },
        {
          action: "moving_agv",
          text: "Média móvel"
        },
        {
          action: "incidence_moving_agv",
          text: "Média móvel de incidência"
        }
      ],
      type_dates: [
        {
          action: "cases",
          text: "Casos"
        },
        {
          action: "deaths",
          text: "Óbitos"
        }
      ],
      statusLoadCities: false,
      statusLoadMicroregions: false,
      statusLoadMesoregions: false
    };
  },
  watch: {
    statusLoadCities: function() {
      if (
        this.statusLoadCities &&
        this.statusLoadMicroregions &&
        this.statusLoadMesoregions
      ) {
        setTimeout(() => {
          this.generateChart();
          setTimeout(() => {
            this.load = false;
          }, 2000);
        }, 500);
      }
    },
    statusLoadMicroregions: function() {
      if (
        this.statusLoadCities &&
        this.statusLoadMicroregions &&
        this.statusLoadMesoregions
      ) {
        setTimeout(() => {
          this.generateChart();
          setTimeout(() => {
            this.load = false;
          }, 2000);
        }, 500);
      }
    },
    statusLoadMesoregions: function() {
      if (
        this.statusLoadCities &&
        this.statusLoadMicroregions &&
        this.statusLoadMesoregions
      ) {
        setTimeout(() => {
          this.generateChart();
          setTimeout(() => {
            this.load = false;
          }, 2000);
        }, 500);
      }
    }
  },
  props: {},
  methods: {
    welcomeMessage() {
      this.$emit("welcomeMessage", { title: this.title });
    },
    async onSelect(items) {
      this.mesoregionsSelected = items;
      this.regionsSearched.forEach(item => {
        item.data.forEach(i => {
          this.getMesoregionCases(i);
        });
      });
    },
    async gets() {
      this.load = true;
      let countCities = 0;
      let countMicroregions = 0;
      let countMesoregions = 0;

      this.statusLoadCities = false;
      this.statusLoadMicroregions = false;
      this.statusLoadMesoregions = false;

      if (!this.citiesSelected.length == 0) {
        await this.citiesSelected.forEach(async id => {
          if (await this.getCityCases(id)) {
            countCities += 1;
          }
          if (this.citiesSelected.length == countCities) {
            this.statusLoadCities = true;
          }
        });
      } else {
        this.statusLoadCities = true;
      }

      if (!this.microregionsSelected.length == 0) {
        await this.microregionsSelected.forEach(async id => {
          if (await this.getMicroregionCases(id)) {
            countMicroregions += 1;
          }
          if (this.microregionsSelected.length == countMicroregions) {
            this.statusLoadMicroregions = true;
          }
        });
      } else {
        this.statusLoadMicroregions = true;
      }

      if (!this.mesoregionsSelected.length == 0) {
        await this.mesoregionsSelected.forEach(async id => {
          if (await this.getMesoregionCases(id)) {
            countMesoregions += 1;
          }
          if (this.mesoregionsSelected.length == countMesoregions) {
            this.statusLoadMesoregions = true;
          }
        });
      } else {
        this.statusLoadMesoregions = true;
      }

      return true;
    },
    changeControl() {
      this.load = true;
      this.gets();

      //this.generateChart();
    },
    generateChart() {
      if (this.chart == null) {
        this.chart = new ApexCharts(
          document.querySelector("#chart"),
          this.chartOptions
        );
        this.chart.render();
      }
      this.chart.destroy();

      let type_date = "";
      let filter = "";

      if (this.type_date == "cases") {
        type_date = "Casos de";
      } else if (this.type_date == "deaths") {
        type_date = "Óbitos por";
      }
      if (this.filter == "per_date") {
        filter = "por dia";
      } else if (this.filter == "incidence") {
        filter = "incidência por dia";
      } else if (this.filter == "moving_agv") {
        filter = "média móvel por dia";
      } else if (this.filter == "incidence_moving_agv") {
        filter = "média móvel de incidência por dia";
      }

      this.chartOptions.series = [];
      this.chartOptions.title.text = `S3BioTech: ${moment(
        String(this.datasets[0].updated_at)
      ).format("DD/MM/YYYY")}`;

      this.chartOptions.yaxis[0].title.text = `${type_date} ${this.datasetName.toUpperCase()} ${filter} Fonte: ${this.source.toUpperCase()}`;
      this.title = this.chartOptions.yaxis[0].title.text;
      this.welcomeMessage();

      let items = [
        {
          selecteds: this.mesoregionsSelected,
          series: this.mesoregionsSeries
        },
        {
          selecteds: this.citiesSelected,
          series: this.citiesSeries
        },
        {
          selecteds: this.microregionsSelected,
          series: this.microregionsSeries
        }
      ];

      items.forEach(item => {
        item.selecteds.forEach(selected => {
          this.years.forEach(year => {
            item.series[year][this.type_date][this.filter].forEach(serie => {
              if (selected == serie.id) {
                this.chartOptions.series.push(serie.data);
              }
            });
          });
        });
      });

      this.chart = new ApexCharts(
        document.querySelector("#chart"),
        this.chartOptions
      );

      this.chart.render();
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    async getDataSets() {
      try {
        let result = await Vue.$http.get(
          `v1/dataset/${this.source}/${this.datasetName}?limit=5`
        );
        this.datasets = await result.data;
        await this.years.push(await this.datasets[0].year);

        await this.datasets.forEach(async dataset => {
          this.citiesSeries[dataset.year] = [];
          this.microregionsSeries[dataset.year] = [];
          this.mesoregionsSeries[dataset.year] = [];

          this.type_dates.forEach(type_date => {
            this.citiesSeries[dataset.year][type_date.action] = [];
            this.microregionsSeries[dataset.year][type_date.action] = [];
            this.mesoregionsSeries[dataset.year][type_date.action] = [];

            this.filters.forEach(filter => {
              this.citiesSeries[dataset.year][type_date.action][
                filter.action
              ] = [];
              this.microregionsSeries[dataset.year][type_date.action][
                filter.action
              ] = [];
              this.mesoregionsSeries[dataset.year][type_date.action][
                filter.action
              ] = [];
            });
          });
        });

        this.mesoregionsSelected = [1, 2, 3, 4];
        this.gets();

        //this.generateChart();

        await this.datasetsSelected.push(await this.datasets[0]);
      } catch (e) {
        await this.$store.dispatch("alerts/error", e);
      }
    },
    async getStates() {
      this.states = [];
      this.statesSearched = [];

      try {
        let result = await Vue.$http.get(`v1/city?type=detail&level=sesapi`);

        this.states = result.data;
        this.statesSearched = JSON.parse(JSON.stringify(this.states));
      } catch (e) {
        await this.$store.dispatch("alerts/error", e);
      }
    },
    async getMesoregionCases(id) {
      await this.years.forEach(async year => {
        let isCache = false;

        await this.mesoregionsSeries[year][this.type_date][this.filter].forEach(
          async mesoregion_sesapi => {
            if (mesoregion_sesapi.id == id) {
              isCache = true;
            }
          }
        );

        if (!isCache) {
          try {
            let result = await Vue.$http.get(
              `v1/mesoregion_sesapi/${this.source}/${this.datasetName}/${year}?type=${this.type_date}&${this.filter}=${id}`
            );
            let serie = result.data.serie;
            serie.name = `Mesorregião: ${serie.name} ${year}`;
            this.mesoregionsSeries[year][this.type_date][this.filter].push({
              id: id,
              data: serie
            });
            return true;
          } catch (e) {
            await this.$store.dispatch("alerts/error", e);
            return false;
          }
        }
        return true;
      });
      return true;
    },
    async getCityCases(id) {
      await this.years.forEach(async year => {
        let isCache = false;

        await this.citiesSeries[year][this.type_date][this.filter].forEach(
          async city => {
            if (city.id == id) {
              isCache = true;
            }
          }
        );

        if (!isCache) {
          try {
            let result = await Vue.$http.get(
              `v1/city/${this.source}/${this.datasetName}/${year}?type=${this.type_date}&${this.filter}=${id}`
            );
            let serie = result.data.serie;
            serie.name = `Cidade: ${serie.name} ${year}`;
            this.citiesSeries[year][this.type_date][this.filter].push({
              id: id,
              data: serie
            });
            return true;
          } catch (e) {
            await this.$store.dispatch("alerts/error", e);
            return false;
          }
        }
        return true;
      });
      return true;
    },
    async getMicroregionCases(id) {
      await this.years.forEach(async year => {
        let isCache = false;

        await this.microregionsSeries[year][this.type_date][
          this.filter
        ].forEach(async microregion_sesapi => {
          if (microregion_sesapi.id == id) {
            isCache = true;
          }
        });

        if (!isCache) {
          try {
            let result = await Vue.$http.get(
              `v1/microregion_sesapi/${this.source}/${this.datasetName}/${year}?type=${this.type_date}&${this.filter}=${id}`
            );
            let serie = result.data.serie;
            serie.name = `Microrregião: ${serie.name} ${year}`;
            this.microregionsSeries[year][this.type_date][this.filter].push({
              id: id,
              data: serie
            });
            return true;
          } catch (e) {
            await this.$store.dispatch("alerts/error", e);
            return false;
          }
        }
        return true;
      });
      return true;
    },
    toLower(text) {
      text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return text.toString().toLowerCase();
    },
    searchByName(items, term, index) {
      if (term) {
        items = items.filter(item => {
          if (this.toLower(item.name).includes(this.toLower(term))) {
            return true;
          } else if (
            this.toLower(item.microregion_sesapi.name).includes(
              this.toLower(term)
            )
          ) {
            return true;
          } else if (
            this.toLower(item.mesoregion_sesapi.name).includes(
              this.toLower(term)
            )
          ) {
            return true;
          }
          return false;
        });
      }
      this.statesSearched[index].cities = items;
    },
    clearSelect() {
      this.mesoregionsSelected = [];
      this.microregionsSelected = [];
      this.citiesSelected = [];
    }
  }
};
</script>
<style lang="scss" scoped>
.md-drawer {
  width: 260px;
  max-width: calc(100vw - 160px);
}

ul div.md-card {
  margin: 0;
}

.md-checkbox {
  margin: 0 16px !important;
}

.container-checkbox {
  background-color: #ffffff;
}

.md-switch {
  margin: 1px !important;
}

.md-table.md-theme-default .md-table-head {
  padding: 0;
  margin: 1px;
  text-align: start;
}
.md-table.md-theme-default .md-table-head:first-child {
  width: 20px;
  padding: 0;
  margin: 1px;
}
.md-table.md-theme-default .md-table-row td:first-child {
  width: 20px;
  padding: 0;
  margin: 1px;
}

.md-table-cell:last-child .md-table-cell-container {
  padding-left: 0 !important;
  text-align: start !important;
}

.md-table-cell .md-table-cell-container {
  padding-left: 0 !important;
  text-align: start !important;
}

.md-table.md-theme-default .md-table-row td {
  text-align: start;
}

thead th {
  background-color: rgba(33, 150, 243, 0.5) !important;
}

.md-table-cell {
  height: 30px !important;
  padding: 0 !important;
}
</style>
